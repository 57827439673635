import { getTenant } from '../../tenant-support/get-tenant';
import { configuration } from '../../configuration';

export function getPrompt(inputstring: string, contentTypes: number[], token: string) {
  const headers = {
    'Content-Type': 'application/json',
    'X-Tenant': getTenant().name,
    Accept: 'application/octet-stream',
    Authorization: `Bearer ${token}`,
  };

  const body = JSON.stringify({
    content: inputstring,
    contentTypes,
  });

  const response = fetch(`${configuration.bffBaseAddress}/aisearch/queries/getprompt`, {
    method: 'POST',
    headers,
    body,
  });

  return response;
}

export function getPromptTemplate(template: string, templateArguments: any, token: string) {
  const headers = {
    'Content-Type': 'application/json',
    'X-Tenant': getTenant().name,
    Authorization: `Bearer ${token}`,
  };

  const body = JSON.stringify({
    template,
    arguments: templateArguments,
  });

  const response = fetch(`${configuration.bffBaseAddress}/aisearch/queries/getprompttemplate`, {
    method: 'POST',
    headers,
    body,
  });

  return response;
}
